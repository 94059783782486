import swal from 'sweetalert';
import { ref } from 'vue';

export const downloadExcel = response => {
  // Create a blob from the response data
  const blob = new Blob([response.data], {
    type: response.headers['content-type'],
  });

  const fileName =
    response.headers['content-disposition'] &&
    response.headers['content-disposition'][1].replace(/["']/g, '');

  // Create a temporary link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName || 'download.xlsx';
  document.body.appendChild(link);

  // Trigger the download
  link.click();

  // Clean up
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
};

export const handleDownloadError = (error = null) => {
  const message = ref('');
  message.value =
    error.value.status === 422
      ? 'לא נמצאו תלמידים רשומים לקורס'
      : 'ארעה שגיאה בהפקת הקובץ, נא לנסות שוב מאוחר יותר';

  swal({
    title: 'שגיאה',
    text: message.value,
    icon: 'error',
  });
};
