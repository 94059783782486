<template>
  <div ref="tableContainer" class="flex flex-1 flex-col h-full">
    <PVDataTable
      v-if="tableContainerRect?.height"
      v-model:filters="filters"
      v-bind="$attrs"
      :value="items"
      paginator
      :rows="NUM_ROWS_PER_PAGE"
      paginator-template="CurrentPageReport PrevPageLink NextPageLink"
      current-page-report-template="{first}-{last} מתוך {totalRecords}"
      paginator-position="top"
      scrollable
      removable-sort
      :selection-mode="selectionMode"
      :loading="loading"
      :scroll-height="`${tableContainerRect?.height - 85 ?? 0}px`"
      table-style="min-width: 50rem"
      @row-select="$emit('onRowSelect', $event)"
      @update:first="value => (firstItemInPaginator = value)">
      <template #empty>אין תוצאות</template>
      <template #paginatorstart>
        <div v-if="includeSearch" class="flex items-center gap-2">
          <SearchInput
            v-model="filters['global'].value"
            placeholder="חיפוש חופשי"
            :style="'neutral'"
            @reset-search="filters['global'].value = ''"/>
          <slot name="filters" />
          <label class="text-secondary-800 text-xs">
            {{ items.length ?? 0 }} {{ label }}
          </label>
          <PVButton
            v-if="allowDownload"
            variant="outlined"
            :loading="csvLoading"
            :style="{ backgroundColor: 'white', border: 'none' }"
            @click="exportCsvByGroup">
            <template #icon>
              <Download :size="14" class="stroke-secondary-900" />
            </template>
          </PVButton>
        </div>
      </template>
      <PVColumn
        field="index"
        header="#"
        style="width: 40px"
        :selection-mode="selectionMode">
        <template #body="{ index }">
          {{ index + 1 + firstItemInPaginator }}
        </template>
      </PVColumn>
      <PVColumn
        v-for="header in headers"
        :key="header.key"
        :style="header.style"
        :sortable="header.sortable"
        :frozen="header.frozen"
        :header="!hasSlot('columnheader') ? header.title : undefined"
        :header-class="hasSlot('columnheader') ? '!p-0' : ''"
        :body-class="hasSlot('columnbody') ? '!p-0' : ''"
        :selection-mode="header.frozen && selectionMode"
        :field="header.key">
        <template v-if="hasSlot('columnheader')" #header>
          <slot name="columnheader" :header="header" />
        </template>
        <template v-if="hasSlot('columnbody')" #body="{ data }">
          <slot name="columnbody" :header="header" :item="data" />
        </template>
      </PVColumn>
      <PVColumn
        v-if="hasSlot('actions')"
        field="actions"
        :selection-mode="selectionMode">
        <template #body="{ data }">
          <slot name="actions" :data="data" />
        </template>
      </PVColumn>
    </PVDataTable>
  </div>
</template>

<script setup lang="ts">
import { SearchInput } from '@amit/foundation';
import { useElementBounding } from '@vueuse/core';
import { ref, onMounted, nextTick, useSlots } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { Download } from 'lucide-vue-next';
import { getSchoolEnrollmentsCsv } from '/@/app/services/facilities';
import { isString } from 'lodash';
import {
  downloadExcel,
  handleDownloadError,
} from '/@/app/composables/excellDownloadUtils';
import { useRoute } from 'vue-router';

const route = useRoute();

import PVDataTable from 'primevue/datatable';
import PVColumn from 'primevue/column';

const slots = useSlots();
const hasSlot = name => !!slots[name];

defineProps({
  headers: {
    type: Array,
    default: () => [],
  },
  items: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  includeSearch: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  selectionMode: {
    type: String,
    default: null,
  },
  allowDownload: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['onRowSelect']);

const firstItemInPaginator = ref(0);
const NUM_ROWS_PER_PAGE = 25;

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const tableContainer = ref(null);
const tableContainerRect = ref(null as any);

onMounted(async () => {
  nextTick(() => {
    tableContainerRect.value = useElementBounding(tableContainer.value);
  });
});

const csvLoading = ref(false);

const exportCsvByGroup = () => {
  const { course, group } = route.params;
  if (!isString(course)) return;
  csvLoading.value = true;
  const { response, onSuccess, onError, error } = getSchoolEnrollmentsCsv(
    course,
    group,
  );
  onSuccess(() => {
    downloadExcel(response.value);
    csvLoading.value = false;
  });
  onError(() => {
    handleDownloadError(error);
    csvLoading.value = false;
  });
};
</script>

<style lang="scss">
.paginator {
  > div {
    margin-right: unset !important;
    margin-left: auto;
  }
}
button[data-pc-group-section='pagebutton'] {
  transform: rotate(180deg);
}
</style>
