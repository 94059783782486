import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

/**
 * Creates a new school
 * @param data
 */
export const addSchool = (data: any) => useApi(http.post('/schools', data));

export const getSchoolEnrollmentsCsv = (
  courseId: string | null = null,
  groupId: string | null = null,
) => {
  let url = 'enrollments/export';
  if (courseId) {
    url += `/${courseId}`;
  }
  if (groupId) {
    url += `/${groupId}`;
  }
  return useApi(http.get(url, { responseType: 'blob' }));
};
